.welcome {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  /* identical to box height, or 120% */

  display: flex;
  align-items: center;

  color: rgba(0, 0, 0, 0.4);
}
.movingDiv {
  background-color: #403a60;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 25px;
  width: fit-content;
}
.dateText {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  /* identical to box height, or 200% */

  display: flex;
  align-items: center;
  color: #ffffff;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 5px;
}
.appointmentText {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;

  /* identical to box height, or 300% */

  display: flex;
  align-items: center;

  text-align: left;
  padding-top: 5px;
  padding-left: 10px;
  color: grey;
}
.clockIcon {
  margin-left: 40px;
  margin-top: 12px;
  width: 20px;
  height: 20px;
}
.arrowIcon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  margin-top: 5px;
}
.futureDiv {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  margin-left: 50px;
  margin-top: 10px;
  border-radius: 25px;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
}
.fDateText {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  /* identical to box height, or 200% */

  display: flex;
  align-items: center;
  color: black;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 5px;
}
.headLineDiv {
  background: #403a60;
  width: 100%;
  height: 50px;
}
.headLineStyling {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  white-space: nowrap;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}
.headLineSeperator {
  border: 2px solid #ffffff;
  transform: rotate(90deg);
}
