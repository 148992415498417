.customServiceStyle {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  align-items: center;
  padding: 10px;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.serviceNameStyles {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #000000;
}
.servicesCaousel .react-multiple-carousel__arrow {
  background: transparent !important;
}
.servicesCaousel .react-multiple-carousel__arrow:before {
  color: #000 !important;
}
