.seeMore {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 30px;
  /* or 250% */
  text-align: left;
  color: blue;
}
.imgDiv {
  background-image: url(../../drawable/cardBackgroundImg.png);
  height: 40vh;
}
.buttonDiv {
  background-color: rebeccapurple;
  height: 10vh;
}
.transparenButtons {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.63);
  border-radius: 0px 0px 5px 5px;
  width: 100%;
  height: 30px;
  /* margin-left: -20px; */
}
.overlayProperties {
  justify-items: end;
}
.tItemText {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 30px;
  /* identical to box height, or 250% */

  color: #ffffff;
}
.fullScreenIcon {
  margin-left: 20px;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  margin-top: 5px;
}
.downloadIcon {
  margin-left: 90px;
  width: 22px;
  height: 18px;
  margin-right: 5px;
  margin-top: 5px;
}
.modelFullScreenbuttons {
  background: rgba(0, 0, 0, 0.63);
  border-radius: 0px 0px 5px 5px;
  width: 99%;
  height: 5vh;
  margin-bottom: 5px;
}
