.footerBox {
  background: #403a60;
}
.TOCStyle {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}
.copyrightStyle {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;

  color: rgba(255, 255, 255, 0.7);
}
