.dServiceStyle {
  background: #403a6079;
  border: 1px solid #403a6079;

  width: 100%;
  padding: 5px;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
}
.lServiceStyle {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.17);
  width: 100%;
  /* padding: 5px; */
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 0px;
}
.lightText {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin: 0 !important;
  text-align: center;

  /* identical to box height, or 150% */

  color: #000000;
}
.darkText {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin: 0 !important;
  text-align: center;
  /* identical to box height, or 150% */

  color: #ffffff;
}
.servicePad {
  padding: 0 !important;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .lightText {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 21px;
    margin: 0 !important;

    /* identical to box height, or 150% */

    color: #000000;
  }
}
