.aboutStyling {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 46px;
  text-align: center;
  color: #403a60;
  padding-top: 45px;
}
.pt {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 24px;
  width: 60%;
  color: #000000;

  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}
