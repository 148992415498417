.mainContainer {
  position: relative;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nameTexts {
  text-align: left;
}

.mBtn {
  width: 100%;
  font-weight: 500;
  justify-content: center;

  display: flex;
  color: azure;
  background: #403a60;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.mBtnRegister {
  width: 100%;
  font-weight: bold;
  justify-content: center;

  display: flex;
  color: #403a60;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.mainContainer {
  width: 50vh;
  align-items: center;
}
.Login-component {
  background-image: url(../../drawable/back.png);

  height: 100vh;
}
.borderStylingLogin {
  /* Global/09. White */

  background: #ffffff;
  border: 1px solid #000000 !important;
  box-sizing: border-box !important;
  border-radius: 0 !important;
}
.react-tel-input {
  font-size: 0.9rem !important;
}

.react-tel-input .form-control {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  position: relative;
  letter-spacing: 0.01rem;
  border-radius: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  width: 100% !important;
  font-size: 0.9rem !important;
}
