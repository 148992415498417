.NavButtonsStyle {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
}
.navTStyle {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 30px;
  /* identical to box height, or 214% */

  color: rgba(0, 0, 0, 0.5);
}
.signUpBtn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.loginUpBtn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background-color: #403a60;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
