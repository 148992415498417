.mCallenderBtn {
  width: 30vh;
  font-weight: 500;
  justify-content: center;
  margin-top: 40px;
  margin-left: 40px;
  display: flex;
  color: azure;
  background: #403a60;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.fc-daygrid-event {
  position: relative;
  white-space: pre-wrap;
  border-radius: 3px;
  font-size: 0.85em;
  font-size: var(--fc-small-font-size, 0.85em);
  background-color: #403a60;
  color: white;
  margin-left: 10px;
}
