.bannerDate {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  /* identical to box height, or 200% */

  align-items: center;
  color: white;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 30px;
}
.bannerStyle {
  background: #403a60;
}
.serviceStyling {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height, or 167% */

  display: flex;
  align-items: center;
  margin-left: 20px;
  color: rgba(0, 0, 0, 0.4);
}
.servicePill {
  margin-left: 20px;
  padding-left: 25px;
  margin-bottom: 20px;
  padding-right: 25px;
  width: max-content;
  height: max-content;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20.5px;
}
.servicetext {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0 !important;
  /* identical to box height, or 187% */

  display: flex;
  align-items: center;

  color: #000000;
}
.hammerIcon {
  width: 30px;
  height: 25px;
  margin-right: 5px;
  margin-top: 5px;
  object-fit: contain;
}
.lineSeperater {
  margin-top: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.countdownStyle {
  margin-top: 30px;
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 80px;
  line-height: 30px;
  /* or 37% */

  align-items: center;
  text-align: center;

  color: #ffffff;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.days {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 30px;
  /* or 300% */

  color: rgba(255, 255, 255, 0.53);
}
.hours {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 30px;
  /* or 300% */

  color: rgba(255, 255, 255, 0.53);
}
.mins {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 30px;
  /* or 300% */

  color: rgba(255, 255, 255, 0.53);
}
.seconds {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 30px;
  /* or 300% */

  color: rgba(255, 255, 255, 0.53);
}
.textAreaStyle {
  border: 1px solid #000000;
  padding: 20px;
  margin-left: 20px;

  width: 668px;
  height: 96px;
}
.dText {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  margin-left: 20px;

  color: #212529;
}
.tDiv {
  margin-top: 40px;
}
.modBtn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #403a60;
  border-radius: 4px;
  color: azure;
  margin-top: 10px;
  margin-left: 20px;
}
.join-meeting-button {
  background-color: #fff;
  border-radius: 20px !important;
  width: 300px;
  border: solid 1px #fff;
  color: #fff;
  font-size: 30px;
  position: absolute;
  bottom: -40px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  font-weight: 900;
  box-shadow: none !important;
}
.join-meeting-inner {
  background-color: #403a60;
  padding: 10px !important;
  color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  margin: none;
}
.join-meeting-button:hover {
  box-shadow: none !important;
}
