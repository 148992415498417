.mainContainer {
  position: relative;
  margin: 50px auto;
}
.nameTexts {
  text-align: left;
}
.mLogo {
  width: 143px;
  height: 114px;
  left: 280px;
  top: 88px;
  cursor: pointer;
}
.mBtn {
  width: 100%;
  font-weight: 500;
  justify-content: center;

  display: flex;
  color: azure;
  background: #403a60;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.mBtnRegister {
  width: 100%;
  font-weight: bold;
  justify-content: center;

  display: flex;
  color: #403a60;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.mainContainer {
  width: 50vh;
  align-items: center;
}
.Login-component {
  background-image: url(../../../drawable/back.png);

  height: 100vh;
}
.mPrevious {
  padding-top: 10px;
  margin-left: 40px;
  width: 30px;
  height: 30px;
}
