.MainName {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height, or 167% */

  display: flex;
  align-items: center;

  color: rgba(0, 0, 0, 0.4);
  padding: 20px;
}
.deleteButton {
  background: #403a60;
  outline: none !important;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: white;
  margin: 20px;
}
