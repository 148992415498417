.cService {
  background: #403a60;
  border-radius: 4px;
  color: white;
}
.cService:disabled {
  background-color: rgba(0, 0, 0, 0.15);
  border: none;
  outline: none;
  cursor: not-allowed;
}
.containerBackStyle {
  background-image: url(https://firebasestorage.googleapis.com/v0/b/cottage-and-manor.appspot.com/o/landing_page_images%2Fbooking.jpeg?alt=media&token=3f9c228c-5dd9-4c66-a9ee-4c29c38b9507);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
}
.jTronStyling {
  background-color: white !important;

  width: 70%;
}
@media only screen and (max-width: 550px) {
  .jTronStyling {
    width: 100%;
  }
}

input.error,
select.error {
  border: rgb(255, 0, 0) 1px solid;
}

.error-text {
  color: red;
  font-weight: 600;
  font-size: 12px;
}
