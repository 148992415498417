.addNewStyling {
  background: #403a60;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: white;
}
.rowDiv {
  margin-left: 140vh;
}
.submitButton {
  background: #403a60;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  color: white;
}
.CloseButton {
  background: #d8d8d8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  color: white;
}
.serviceContainer {
  justify-content: right;
  width: 100%;
}
.rowWidth {
  width: max-content;
}
