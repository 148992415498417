@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap);
html {
  scroll-behavior: smooth;
}
body,
* {
  /* scroll-behavior: smooth; */
  margin: 0;
  font-family: "Roboto Slab", serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
body {
  overflow-x: hidden;
}
.video-call-button {
  background: white;
  border: none;
  outline: none;
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 70px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
#root {
  overflow-x: hidden;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.NavButtonsStyle {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
}
.navTStyle {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 30px;
  /* identical to box height, or 214% */

  color: rgba(0, 0, 0, 0.5);
}
.signUpBtn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.loginUpBtn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background-color: #403a60;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.mainContainer {
  position: relative;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nameTexts {
  text-align: left;
}

.mBtn {
  width: 100%;
  font-weight: 500;
  justify-content: center;

  display: flex;
  color: azure;
  background: #403a60;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.mBtnRegister {
  width: 100%;
  font-weight: bold;
  justify-content: center;

  display: flex;
  color: #403a60;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.mainContainer {
  width: 50vh;
  align-items: center;
}
.Login-component {
  background-image: url(/static/media/back.638019c0.png);

  height: 100vh;
}
.borderStylingLogin {
  /* Global/09. White */

  background: #ffffff;
  border: 1px solid #000000 !important;
  box-sizing: border-box !important;
  border-radius: 0 !important;
}
.react-tel-input {
  font-size: 0.9rem !important;
}

.react-tel-input .form-control {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  position: relative;
  letter-spacing: 0.01rem;
  border-radius: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  width: 100% !important;
  font-size: 0.9rem !important;
}

.mainContainer {
  position: relative;
  margin: 50px auto;
}
.nameTexts {
  text-align: left;
}
.mLogo {
  width: 143px;
  height: 114px;
  left: 280px;
  top: 88px;
  cursor: pointer;
}
.mBtn {
  width: 100%;
  font-weight: 500;
  justify-content: center;

  display: flex;
  color: azure;
  background: #403a60;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.mBtnRegister {
  width: 100%;
  font-weight: bold;
  justify-content: center;

  display: flex;
  color: #403a60;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.mainContainer {
  width: 50vh;
  align-items: center;
}
.Login-component {
  background-image: url(/static/media/back.638019c0.png);

  height: 100vh;
}
.mPrevious {
  padding-top: 10px;
  margin-left: 40px;
  width: 30px;
  height: 30px;
}




.welcome {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  /* identical to box height, or 120% */

  display: flex;
  align-items: center;

  color: rgba(0, 0, 0, 0.4);
}
.movingDiv {
  background-color: #403a60;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 25px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.dateText {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  /* identical to box height, or 200% */

  display: flex;
  align-items: center;
  color: #ffffff;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 5px;
}
.appointmentText {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;

  /* identical to box height, or 300% */

  display: flex;
  align-items: center;

  text-align: left;
  padding-top: 5px;
  padding-left: 10px;
  color: grey;
}
.clockIcon {
  margin-left: 40px;
  margin-top: 12px;
  width: 20px;
  height: 20px;
}
.arrowIcon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  margin-top: 5px;
}
.futureDiv {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  margin-left: 50px;
  margin-top: 10px;
  border-radius: 25px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
}
.fDateText {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  /* identical to box height, or 200% */

  display: flex;
  align-items: center;
  color: black;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 5px;
}
.headLineDiv {
  background: #403a60;
  width: 100%;
  height: 50px;
}
.headLineStyling {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  white-space: nowrap;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}
.headLineSeperator {
  border: 2px solid #ffffff;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.seeMore {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 30px;
  /* or 250% */
  text-align: left;
  color: blue;
}
.imgDiv {
  background-image: url(/static/media/cardBackgroundImg.893f144d.png);
  height: 40vh;
}
.buttonDiv {
  background-color: rebeccapurple;
  height: 10vh;
}
.transparenButtons {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.63);
  border-radius: 0px 0px 5px 5px;
  width: 100%;
  height: 30px;
  /* margin-left: -20px; */
}
.overlayProperties {
  justify-items: end;
}
.tItemText {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 30px;
  /* identical to box height, or 250% */

  color: #ffffff;
}
.fullScreenIcon {
  margin-left: 20px;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  margin-top: 5px;
}
.downloadIcon {
  margin-left: 90px;
  width: 22px;
  height: 18px;
  margin-right: 5px;
  margin-top: 5px;
}
.modelFullScreenbuttons {
  background: rgba(0, 0, 0, 0.63);
  border-radius: 0px 0px 5px 5px;
  width: 99%;
  height: 5vh;
  margin-bottom: 5px;
}

.bannerDate {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  /* identical to box height, or 200% */

  align-items: center;
  color: white;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 30px;
}
.bannerStyle {
  background: #403a60;
}
.serviceStyling {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height, or 167% */

  display: flex;
  align-items: center;
  margin-left: 20px;
  color: rgba(0, 0, 0, 0.4);
}
.servicePill {
  margin-left: 20px;
  padding-left: 25px;
  padding-right: 25px;
  width: -webkit-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: max-content;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20.5px;
}
.servicetext {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  /* identical to box height, or 187% */

  display: flex;
  align-items: center;

  color: #000000;
}
.hammerIcon {
  width: 30px;
  height: 25px;
  margin-right: 5px;
  margin-top: 5px;
  object-fit: contain;
}
.lineSeperater {
  margin-top: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.countdownStyle {
  margin-top: 30px;
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 80px;
  line-height: 30px;
  /* or 37% */

  align-items: center;
  text-align: center;

  color: #ffffff;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.days {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 30px;
  /* or 300% */

  color: rgba(255, 255, 255, 0.53);
}
.hours {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 30px;
  /* or 300% */

  color: rgba(255, 255, 255, 0.53);
}
.mins {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 30px;
  /* or 300% */

  color: rgba(255, 255, 255, 0.53);
}
.seconds {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 30px;
  /* or 300% */

  color: rgba(255, 255, 255, 0.53);
}
.coloumCountStyle {
  width: -webkit-min-content;
  width: min-content;
}

.bannerDate {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  /* identical to box height, or 200% */

  align-items: center;
  color: white;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 30px;
}
.bannerStyle {
  background: #403a60;
}
.serviceStyling {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height, or 167% */

  display: flex;
  align-items: center;
  margin-left: 20px;
  color: rgba(0, 0, 0, 0.4);
}
.servicePill {
  margin-left: 20px;
  padding-left: 25px;
  margin-bottom: 20px;
  padding-right: 25px;
  width: -webkit-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: max-content;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20.5px;
}
.servicetext {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0 !important;
  /* identical to box height, or 187% */

  display: flex;
  align-items: center;

  color: #000000;
}
.hammerIcon {
  width: 30px;
  height: 25px;
  margin-right: 5px;
  margin-top: 5px;
  object-fit: contain;
}
.lineSeperater {
  margin-top: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.countdownStyle {
  margin-top: 30px;
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 80px;
  line-height: 30px;
  /* or 37% */

  align-items: center;
  text-align: center;

  color: #ffffff;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.days {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 30px;
  /* or 300% */

  color: rgba(255, 255, 255, 0.53);
}
.hours {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 30px;
  /* or 300% */

  color: rgba(255, 255, 255, 0.53);
}
.mins {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 30px;
  /* or 300% */

  color: rgba(255, 255, 255, 0.53);
}
.seconds {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 30px;
  /* or 300% */

  color: rgba(255, 255, 255, 0.53);
}
.textAreaStyle {
  border: 1px solid #000000;
  padding: 20px;
  margin-left: 20px;

  width: 668px;
  height: 96px;
}
.dText {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  margin-left: 20px;

  color: #212529;
}
.tDiv {
  margin-top: 40px;
}
.modBtn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #403a60;
  border-radius: 4px;
  color: azure;
  margin-top: 10px;
  margin-left: 20px;
}
.join-meeting-button {
  background-color: #fff;
  border-radius: 20px !important;
  width: 300px;
  border: solid 1px #fff;
  color: #fff;
  font-size: 30px;
  position: absolute;
  bottom: -40px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  font-weight: 900;
  box-shadow: none !important;
}
.join-meeting-inner {
  background-color: #403a60;
  padding: 10px !important;
  color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  margin: none;
}
.join-meeting-button:hover {
  box-shadow: none !important;
}

.aboutStyling {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 46px;
  text-align: center;
  color: #403a60;
  padding-top: 45px;
}
.pt {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 24px;
  width: 60%;
  color: #000000;

  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

.dServiceStyle {
  background: #403a6079;
  border: 1px solid #403a6079;

  width: 100%;
  padding: 5px;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
}
.lServiceStyle {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.17);
  width: 100%;
  /* padding: 5px; */
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 0px;
}
.lightText {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin: 0 !important;
  text-align: center;

  /* identical to box height, or 150% */

  color: #000000;
}
.darkText {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin: 0 !important;
  text-align: center;
  /* identical to box height, or 150% */

  color: #ffffff;
}
.servicePad {
  padding: 0 !important;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .lightText {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 21px;
    margin: 0 !important;

    /* identical to box height, or 150% */

    color: #000000;
  }
}

.fc .fc-daygrid-day-frame {
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  height: 100px !important;
}

.cService {
  background: #403a60;
  border-radius: 4px;
  color: white;
}
.cService:disabled {
  background-color: rgba(0, 0, 0, 0.15);
  border: none;
  outline: none;
  cursor: not-allowed;
}
.containerBackStyle {
  background-image: url(https://firebasestorage.googleapis.com/v0/b/cottage-and-manor.appspot.com/o/landing_page_images%2Fbooking.jpeg?alt=media&token=3f9c228c-5dd9-4c66-a9ee-4c29c38b9507);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
}
.jTronStyling {
  background-color: white !important;

  width: 70%;
}
@media only screen and (max-width: 550px) {
  .jTronStyling {
    width: 100%;
  }
}

input.error,
select.error {
  border: rgb(255, 0, 0) 1px solid;
}

.error-text {
  color: red;
  font-weight: 600;
  font-size: 12px;
}

.caroselStyling {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.jumboStyling {
  /*background-image: url(https://firebasestorage.googleapis.com/v0/b/cottage-and-manor.appspot.com/o/landing_page_images%2Fbanner.png?alt=media&token=66a12843-3db7-4cee-8588-ecd7673479e1);*/
  /*padding: 0 !important;*/
  /*background-position: center;*/
  /*background-repeat: no-repeat;*/
  /*background-size: cover;*/
  /*height: 80vh;*/
  background: white!important;
}
.mobile-image img {
  width: 100%;
}
.headerStyling {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 900;
  font-size: 2.5rem;
  line-height: 53px;
  text-transform: uppercase;
  /*width: 40%;*/
  color: #403a60;

  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}
.pStyling {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 24px;
  text-transform: uppercase;
  /*width: 50%;*/
  color: #000000;

  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}
.bStyle {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: #403a60;
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;

  /* identical to box height, or 105% */

  display: flex;
  align-items: center;
  text-align: center;
  align-self: flex-start;
  color: #403a60;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}
.makeOverlay {
  height: 100% !important;
  width: 100% !important;
  background: transparent;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 15%;
}
.allTextsstylings {
  margin-top: 300px;
}
@media only screen and (max-width: 600px) {
  .headerStyling {
    /*width: 80%;*/
    word-wrap: break-word;
  }
  .pStyling {
    /*width: 80%;*/
  }
  .bStyle {
    text-align: center;
    align-self: flex-start;
    margin-left: 10%;
    background-color: snow;
    color: snow;
    border: none;
  }
  .makeOverlay {
    align-items: center;
    padding: 0 !important;
  }
  .jumboStyling {
    height: 100vh;
  }
}

.formColStyle {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.fLabelStyles {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Text/01. Body */

  color: #212529;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
}

.footerBox {
  background: #403a60;
}
.TOCStyle {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}
.copyrightStyle {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;

  color: rgba(255, 255, 255, 0.7);
}

.ourAppDiv {
  background-image: url(https://firebasestorage.googleapis.com/v0/b/cottage-and-manor.appspot.com/o/landing_page_images%2Fdownload.jpg?alt=media&token=8e92be3a-14cb-4450-8ea9-7964904ef86c);

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
  justify-content: center;
}
.ourAppDetailsStyle {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-top: 150px !important;
}
.dOA {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
  line-height: 66px;
  /* identical to box height */

  text-transform: uppercase;

  color: #000000;
}
.customButtonsStyle {
  height: 10px;
}
@media only screen and (max-width: 600px) {
  .dOA {
    font-size: 30px;
    line-height: 36px;
  }
}

.ios-app-button  a {
  background: url(/static/media/apple-download-image.5b180345.png) 0% 0% / contain no-repeat!important;
}
.customServiceStyle {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  align-items: center;
  padding: 10px;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.serviceNameStyles {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #000000;
}
.servicesCaousel .react-multiple-carousel__arrow {
  background: transparent !important;
}
.servicesCaousel .react-multiple-carousel__arrow:before {
  color: #000 !important;
}

.padding-top {
  padding-top: 130px;
}

.mCallenderBtn {
  width: 30vh;
  font-weight: 500;
  justify-content: center;
  margin-top: 40px;
  margin-left: 40px;
  display: flex;
  color: azure;
  background: #403a60;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.fc-daygrid-event {
  position: relative;
  white-space: pre-wrap;
  border-radius: 3px;
  font-size: 0.85em;
  font-size: 0.85em;
  font-size: var(--fc-small-font-size, 0.85em);
  background-color: #403a60;
  color: white;
  margin-left: 10px;
}

.pName {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;

  /* identical to box height, or 200% */

  display: flex;
  align-items: center;

  color: #000000;
}
.pDate {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;

  /* identical to box height, or 300% */

  color: rgba(0, 0, 0, 0.2);
}
.pId {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;

  /* identical to box height, or 300% */

  color: rgba(0, 0, 0, 0.2);
}
.pSeperater {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.pService {
  margin-left: -30px;
}
.pCancel {
  background: #d20000;
  color: aliceblue;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.pApprove {
  background: #403a60;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: aliceblue;
}
.inspec {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  /* identical to box height, or 250% */

  display: flex;
  align-items: center;

  color: #aeaeae;
}

.nApps {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height, or 167% */

  display: flex;
  align-items: center;
  padding: 20px;

  color: rgba(0, 0, 0, 0.4);
}
/* width */
::-webkit-scrollbar {
  width: 2px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #403a60;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #403a60;
}

.servicePill {
  margin-left: 20px;
  padding-left: 25px;
  padding-right: 25px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20.5px;
}
.servicetext {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;

  color: #000000;
}
.JobTitle {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  /* identical to box height, or 250% */

  display: flex;
  align-items: center;

  color: #aeaeae;
}

.cancelButtonDesign {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: #403a60;
}

.pName {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;

  /* identical to box height, or 200% */

  display: flex;
  align-items: center;

  color: #000000;
}
.pDate {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;

  /* identical to box height, or 300% */

  color: rgba(0, 0, 0, 0.2);
}
.pId {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;

  /* identical to box height, or 300% */

  color: rgba(0, 0, 0, 0.2);
}
.pSeperater {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.pService {
  margin-left: -30px;
}
.pCancel {
  background: #d20000;
  color: aliceblue;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.pApprove {
  background: #403a60;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: aliceblue;
}
.inspec {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  /* identical to box height, or 250% */

  display: flex;
  align-items: center;

  color: #aeaeae;
}

input[type="checkbox"]:hover,
input[type="checkbox"]:active,
input[type="checkbox"]:focus,
input[type="checkbox"] {
  --webkit-appearance: checkbox !important;
}

.addNewStyling {
  background: #403a60;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: white;
}
.rowDiv {
  margin-left: 140vh;
}
.submitButton {
  background: #403a60;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  color: white;
}
.CloseButton {
  background: #d8d8d8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  color: white;
}
.serviceContainer {
  justify-content: right;
  width: 100%;
}
.rowWidth {
  width: -webkit-max-content;
  width: max-content;
}

.MainName {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height, or 167% */

  display: flex;
  align-items: center;

  color: rgba(0, 0, 0, 0.4);
  padding: 20px;
}
.deleteButton {
  background: #403a60;
  outline: none !important;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: white;
  margin: 20px;
}

.fc .fc-daygrid-day-frame {
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  height: 100px !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}
#root {
  height: 100vh;
}
.sticky-navbar-landing-page {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 20;
  background: white;
  border-bottom: solid 1px #403b60;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  /* -webkit-appearance: none; */
  box-shadow: none !important;
}

