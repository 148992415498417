.servicePill {
  margin-left: 20px;
  padding-left: 25px;
  padding-right: 25px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20.5px;
}
.servicetext {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;

  color: #000000;
}
.JobTitle {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  /* identical to box height, or 250% */

  display: flex;
  align-items: center;

  color: #aeaeae;
}
