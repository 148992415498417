.ourAppDiv {
  background-image: url(https://firebasestorage.googleapis.com/v0/b/cottage-and-manor.appspot.com/o/landing_page_images%2Fdownload.jpg?alt=media&token=8e92be3a-14cb-4450-8ea9-7964904ef86c);

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
  justify-content: center;
}
.ourAppDetailsStyle {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-top: 150px !important;
}
.dOA {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
  line-height: 66px;
  /* identical to box height */

  text-transform: uppercase;

  color: #000000;
}
.customButtonsStyle {
  height: 10px;
}
@media only screen and (max-width: 600px) {
  .dOA {
    font-size: 30px;
    line-height: 36px;
  }
}

.ios-app-button  a {
  background: url(../../../drawable/apple-download-image.png) 0% 0% / contain no-repeat!important;
}