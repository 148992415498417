.pName {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;

  /* identical to box height, or 200% */

  display: flex;
  align-items: center;

  color: #000000;
}
.pDate {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;

  /* identical to box height, or 300% */

  color: rgba(0, 0, 0, 0.2);
}
.pId {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;

  /* identical to box height, or 300% */

  color: rgba(0, 0, 0, 0.2);
}
.pSeperater {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.pService {
  margin-left: -30px;
}
.pCancel {
  background: #d20000;
  color: aliceblue;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.pApprove {
  background: #403a60;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: aliceblue;
}
.inspec {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  /* identical to box height, or 250% */

  display: flex;
  align-items: center;

  color: #aeaeae;
}
