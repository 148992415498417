.caroselStyling {
  height: fit-content;
}
.jumboStyling {
  /*background-image: url(https://firebasestorage.googleapis.com/v0/b/cottage-and-manor.appspot.com/o/landing_page_images%2Fbanner.png?alt=media&token=66a12843-3db7-4cee-8588-ecd7673479e1);*/
  /*padding: 0 !important;*/
  /*background-position: center;*/
  /*background-repeat: no-repeat;*/
  /*background-size: cover;*/
  /*height: 80vh;*/
  background: white!important;
}
.mobile-image img {
  width: 100%;
}
.headerStyling {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 900;
  font-size: 2.5rem;
  line-height: 53px;
  text-transform: uppercase;
  /*width: 40%;*/
  color: #403a60;

  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}
.pStyling {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 24px;
  text-transform: uppercase;
  /*width: 50%;*/
  color: #000000;

  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}
.bStyle {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: #403a60;
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;

  /* identical to box height, or 105% */

  display: flex;
  align-items: center;
  text-align: center;
  align-self: flex-start;
  color: #403a60;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}
.makeOverlay {
  height: 100% !important;
  width: 100% !important;
  background: transparent;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 15%;
}
.allTextsstylings {
  margin-top: 300px;
}
@media only screen and (max-width: 600px) {
  .headerStyling {
    /*width: 80%;*/
    word-wrap: break-word;
  }
  .pStyling {
    /*width: 80%;*/
  }
  .bStyle {
    text-align: center;
    align-self: flex-start;
    margin-left: 10%;
    background-color: snow;
    color: snow;
    border: none;
  }
  .makeOverlay {
    align-items: center;
    padding: 0 !important;
  }
  .jumboStyling {
    height: 100vh;
  }
}
